exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-etimo-ventures-tsx": () => import("./../../../src/pages/etimo-ventures.tsx" /* webpackChunkName: "component---src-pages-etimo-ventures-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karriar-tsx": () => import("./../../../src/pages/karriar.tsx" /* webpackChunkName: "component---src-pages-karriar-tsx" */),
  "component---src-pages-kunder-tsx": () => import("./../../../src/pages/kunder.tsx" /* webpackChunkName: "component---src-pages-kunder-tsx" */),
  "component---src-pages-om-oss-tsx": () => import("./../../../src/pages/om-oss.tsx" /* webpackChunkName: "component---src-pages-om-oss-tsx" */),
  "component---src-pages-tjanster-tsx": () => import("./../../../src/pages/tjanster.tsx" /* webpackChunkName: "component---src-pages-tjanster-tsx" */)
}

